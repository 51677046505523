<template>
    <div>
        <h1 class="page__title">Создать пользователя</h1>

        <div class="intro-y box p-5 mt-5">
            <form method="post" class="grid grid-cols-12 gap-6" :class="{ 'disabled-form': loading }">
                <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
                    <Preloader />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-name" class="form-label">Имя <sup>*</sup></label>
                    <input
                        id="form-name"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        required
                        autocomplete="no"
                        v-model="form.name"
                    />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-email" class="form-label">Email <sup>*</sup></label>
                    <input
                        id="form-email"
                        type="email"
                        class="form-control w-full"
                        placeholder=""
                        required
                        autocomplete="no"
                        v-model="form.email"
                    />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-phone" class="form-label">Телефон</label>
                    <input
                        id="form-phone"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        autocomplete="no"
                        v-model="form.phone"
                    />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-password" class="form-label">Пароль <sup>*</sup></label>
                    <input
                        id="form-password"
                        type="password"
                        class="form-control w-full"
                        placeholder=""
                        required
                        autocomplete="no"
                        v-model="form.password"
                    />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-password-confirmed" class="form-label">Подтвердите пароль <sup>*</sup></label>
                    <input
                        id="form-password-confirmed"
                        type="password"
                        class="form-control w-full"
                        placeholder=""
                        required
                        autocomplete="off"
                        v-model="form.password_confirmation"
                    />
                </div>

                <div v-if="roles && roles.length > 0" class="col-span-6">
                    <label for="form-roles" class="form-label">Роль <sup>*</sup></label>
                    <Multiselect
                        id="form-roles"
                        name="type"
                        :value="null"
                        :searchable="false"
                        track-by="title"
                        label="title"
                        valueProp="id"
                        :options="roles"
                        :can-clear="false"
                        placeholder="Не выбрано"
                        noResultsText="Не найдено"
                        noOptionsText="Не найдено"
                        v-model="form._role"
                    />
                </div>

                <div v-if="affiliates && affiliates.length > 0 && form._role !== 'super-admin'" class="col-span-6">
                    <label for="form-affiliates" class="form-label">Филиал <sup>*</sup></label>
                    <Multiselect
                        id="form-affiliates"
                        name="type"
                        mode="tags"
                        :value="null"
                        :searchable="false"
                        track-by="name"
                        label="name"
                        valueProp="id"
                        :options="affiliates"
                        :can-clear="false"
                        placeholder="Не выбрано"
                        noResultsText="Не найдено"
                        noOptionsText="Не найдено"
                        v-model="form.affiliates"
                    />
                </div>

                <div class="col-span-12 flex items-center justify-end mt-5">
                    <router-link :to="{ name: 'users' }" class="btn btn-secondary w-24 mr-2 mb-2">Отмена</router-link>
                    <button type="button" class="btn btn-primary mr-2 mb-2" @click="() => save(true)">
                        Сохранить и продолжить
                    </button>
                    <button type="button" class="btn btn-primary mb-2" @click="save(false)">Сохранить</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import SimpleTable from '@/components/tables/SimpleTable';
import Preloader from '@/components/preloader/Main';
import { errorResponse } from '@/mixins/form/form-mixin';

export default {
    components: { SimpleTable, Preloader },
    name: 'Create',
    mixins: [errorResponse],
    data() {
        return {
            loading: false,
            form: {
                name: '',
                email: '',
                phone: '',
                password: '',
                password_confirmation: '',
                affiliates: [],
                _role: '',
            },
            affiliates: [],
            roles: [],
        };
    },
    async created() {
        await this.fetchAffiliates();
        await this.fetchRoles();
    },
    methods: {
        async fetchAffiliates() {
            try {
                this.loading = true;
                const { data } = await this.axios.get('/affiliates', { params: { paginate: false } });
                this.affiliates = data.data;
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.error('cant fetch affiliates: ' + ex);
            }
        },
        async fetchRoles() {
            try {
                // this.loading = true;
                // const { data } = await this.axios.get('/roles', { params: { paginate: false } });
                // this.roles = data.data;
                this.roles = [
                    {
                        id: 'affiliate-admin',
                        title: 'Администратор филиала',
                    },
                    {
                        id: 'super-admin',
                        title: 'Супер администратор',
                    },
                ];
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.error('cant fetch roles: ' + ex);
            }
        },
        save(flag) {
            this.loading = true;

            this.axios
                .post('/users', { ...this.form })
                .then((rea) => {
                    this.loading = false;

                    if (flag) {
                        this.$router.push({ name: 'userEdit', params: { id: rea.data.data.id } });
                        this.$notify('Запись добавлена');
                    } else {
                        this.$router.push({ name: 'users' });
                    }
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                });
        },
    },
};
</script>

<style></style>
