<template>
  <div class="simple-table">
    <Preloader v-if="loading" class="margin"/>

    <template v-else>
      <div class="table-wrapper">
        <table class="table">
          <thead>
          <tr class="border-b-2">
            <th
              class="whitespace-nowrap header-action"
              v-for="header in headers" :key="header.field"
              :class="{active: header.field === sortField}"
              @click.prevent="sort(header.field)"
            >
          <span>{{ header.label }}
            <ChevronDownIcon
              class="chevron-small"
              :class="{active: header.field === sortField, up: currentSortOrder === 'desc'}"
            />
          </span>
            </th>
            <th>
              Действия
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            class="bg-gray-200 dark:bg-dark-1 table-row"
            v-for="(row, rowIdx) in tableData"
            :key="rowIdx"
          >
            <td
              class="border-b dark:border-dark-5"
              v-for="(header, headerIdx) in headers"
              :key="headerIdx"
            >
              {{ row[header.field] }}
            </td>
            <td class="border-b dark:border-dark-5">
              <div class="actions-container">
                <a
                  v-if="hasAction('edit')"
                  href="#" class="action-btn bg-primary-9" title="редактировать" @click.prevent="editHandler(row)"
                >
                  <Edit2Icon fill="none" stroke="#fff" class="icon-small"></Edit2Icon>
                </a>

                <template v-if="hasAction('block')">
                  <a
                    v-if="row.is_blocked"
                    href="#" class="action-btn bg-primary-11" title="разблокировать" @click.prevent="unblockHandler(row)"
                  >
                    <EyeIcon stroke="#fff" class="icon-small"></EyeIcon>
                  </a>

                  <a
                    v-else
                    href="#" class="action-btn bg-primary-10" title="заблокировать" @click.prevent="blockHandler(row)"
                  >
                    <EyeOffIcon stroke="#fff" class="icon-small"></EyeOffIcon>
                  </a>
                </template>

                <a
                  v-if="hasAction('delete')"
                  href="#" class="action-btn bg-red-600" title="удалить" @click.prevent="deleteHandler(row)"
                >
                  <Trash2Icon stroke="#fff" class="icon-small"></Trash2Icon>
                </a>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <Pagination
        v-if="meta && meta.total > 15"
        :current-page="meta.current_page"
        :last-page="meta.last_page"
      />
    </template>
  </div>
</template>

<script>
import Preloader from '@/components/preloader/Main';
import Pagination from '@/components/pagination/Main';

export default {
    name: 'SimpleTable',
    components: { Pagination, Preloader },
    props: {
      loading: {
        type: Boolean,
      },
      headers: {
        type: Array,
        required: true,
      },
      tableRows: {
        type: Array,
        required: true,
      },
      tableActions: {
          type: Array,
          default() {
            return ['delete', 'edit', 'block'];
          },
      },
      deleteHandler: {
        type: Function,
      },
      blockHandler: {
        type: Function,
      },
      unblockHandler: {
        type: Function,
      },
      editHandler: {
        type: Function,
      },
      meta: {
        type: Object,
      },
    },
    data() {
      return {
        currentSortOrder: 'asc',
        sortField: null,
      };
    },
    computed: {
      tableData() {
        if (!(this.currentSortOrder && this.sortField)) {
          return this.tableRows;
        }
        return this.tableRows.sort((a, b) => {
          let modifier = 1;
          if (this.currentSortOrder === 'desc') modifier = -1;
          if (a[this.sortField] < b[this.sortField]) return -1 * modifier;
          if (a[this.sortField] > b[this.sortField]) return 1 * modifier;
          return 0;
        });
      },

      hasAction() {
        return (actionName) => {
          return this.tableActions.findIndex(item => item === actionName) !== -1;
        };
      }
    },
    methods: {
      sort(field) {
        if (field === this.sortField) {
          this.currentSortOrder = this.currentSortOrder === 'asc' ? 'desc' : 'asc';
        }
        this.sortField = field;
      },
    },
  };
</script>

<style scoped lang="scss">
  .table-wrapper {
    background-color: #fff;
  }

  .chevron-small {
    width: 20px;
    height: 20px;
    transition: .2s;
    opacity: 0;

    &.active {
      opacity: 1;
    }

    &.up {
      transform: rotate(180deg);
    }
  }

  .icon-small {
    width: 15px;
    height: 15px;
  }

  .header-action {
    cursor: pointer;
    transition: .2s;

    span {
      display: block;
    }

    &.active {
      background: #fafafa;
    }
  }

  .table-row {
    transition: .2s;
    background: #fff;

    &:nth-child(odd) {
      background: #fafafa;
    }

    &:hover {
      background: #eee;
    }
  }

  .actions-container {
    display: flex;
  }

  .action-btn {
    display: flex;
    width: 30px;
    height: 30px;
    padding: 5px;
    margin-left: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
  }
</style>
